import React from 'react';
import "./skills.css";


const Skills = () => {
  return (
    <section className="skills section" id='skills'>
        <h2 className="section__title" id='skills'>Skills</h2>
        <span className="section__subtitle">My Technical Skills</span>

        
    </section>
  )
}

export default Skills;